
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["add", "delete", "selectionActiveChange"],
  props: {
    label: String,
    src: String,
    isSelectionActive: Boolean,
    imgSize: String,
    imgType: String,
    disabled: Boolean
  }
})
export default class MIconFileInput extends Vue {
  label!: string;
  src?: string;
  isSelectionActive!: boolean;
  imgSize? = "";
  imgType = "";
  disabled = false;

  // TODO : imgSizeの値によって、template中に色んなのクラスを条件別に当てている。今後imgSizeの種類が増える場合、ちゃんと整理する必要あり。
  get dynamicClass(): string {
    const classes: string[] = [];
    if (this.imgSize === "") {
      classes.push("w-24");
      classes.push("h-24");
      classes.push("rounded-full");
    } else if (this.imgSize === "wide") {
      classes.push("w-full");
      classes.push("h-44");
      classes.push("rounded-lg");
      classes.push("p-5");
    }

    return classes.join(" ");
  }

  get imageType(): string {
    if (this.imgType === "icon") {
      return "アイコン";
    } else if (this.imgType === "logo") {
      return "ロゴ";
    } else {
      return "画像";
    }
  }

  triggerFileInput() {
    // onFileChangeを呼ぶ
    this.$emit("selectionActiveChange");
    (this.$refs.fileInput as HTMLInputElement).click();
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
    const file = input.files[0];
    const allowedExtensions = ["jpeg", "jpg", "svg", "png", "tiff", "tif"];
    const extension = file.name
      .split(".")
      .pop()
      ?.toLowerCase();

    if (extension && allowedExtensions.includes(extension)) {
      this.$emit("add", event);
    } else {
      alert("無効なファイル形式です");
    }
  }
}
