import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/pencil.png'


const _hoisted_1 = { class: "leading-7 text-sm font-medium text-gray-500" }
const _hoisted_2 = { class: "absolute bottom-4 right-4 w-6 h-6 bg-white rounded-full flex justify-center items-center" }
const _hoisted_3 = { class: "bg-white shadow-lg rounded w-40 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("p", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createVNode("div", {
      class: ["relative  bg-gray-300 ", _ctx.dynamicClass]
    }, [
      (_ctx.src)
        ? (_openBlock(), _createBlock("img", {
            key: 0,
            src: _ctx.src,
            alt: "miniAppIcon",
            class: ["block w-full h-full", 
        _ctx.imgSize === 'wide'
          ? ['rounded-none object-contain']
          : ['rounded-full object-cover']
      ]
          }, null, 10, ["src"]))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_2, [
        _createVNode("img", {
          src: _imports_0,
          alt: "pencil",
          class: ["block w-2/4", [
          _ctx.disabled ? 'pointer-events-none	cursor-default' : 'cursor-pointer'
        ]],
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selectionActiveChange')))
        }, null, 2),
        _createVNode("div", {
          class: ["absolute pt-2 z-10 top-full", [
          _ctx.isSelectionActive ? 'block' : 'hidden',
          _ctx.imgSize === 'wide' ? '' : 'left-full'
        ]]
        }, [
          _createVNode("div", _hoisted_3, [
            _createVNode("ul", null, [
              (!_ctx.src)
                ? (_openBlock(), _createBlock("li", {
                    key: 0,
                    class: "list-none flex items-center w-full px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args)))
                  }, _toDisplayString(`${_ctx.imageType}を追加する`), 1))
                : _createCommentVNode("", true),
              (_ctx.src)
                ? (_openBlock(), _createBlock("li", {
                    key: 1,
                    class: "list-none flex items-center w-full px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args)))
                  }, _toDisplayString(`${_ctx.imageType}を変更する`), 1))
                : _createCommentVNode("", true),
              (_ctx.src)
                ? (_openBlock(), _createBlock("li", {
                    key: 2,
                    class: "list-none flex items-center w-full px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer",
                    onClick: _cache[4] || (_cache[4] = 
                () => {
                  _ctx.$emit('delete');
                }
              )
                  }, _toDisplayString(`${_ctx.imageType}を削除する`), 1))
                : _createCommentVNode("", true),
              _createVNode("input", {
                ref: "fileInput",
                type: "file",
                style: {"display":"none"},
                onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
              }, null, 544)
            ])
          ])
        ], 2)
      ])
    ], 2)
  ], 64))
}