
import MBaseModal from "@/components/MBaseModal.vue";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MIconFileInput from "../form/MIconFileInput.vue";
import { Options, Vue } from "vue-class-component";
import firebase from "firebase/app";
import "firebase/storage";
import store from "@/store";

@Options({
  components: {
    MBaseModal,
    MIcon,
    MButton,
    MTextField,
    MIconFileInput,
    MSelectBox
  },
  emits: ["close"],
  props: {
    mainButtonSetting: Object
  }
})
export default class MMiniAppEditModal extends Vue {
  mainButtonSetting!: {
    [key: string]:
      | string
      | number
      | boolean
      | firebase.firestore.DocumentReference;
  };

  ref = this.mainButtonSetting.ref;
  buttonValue = this.mainButtonSetting.value;
  buttonLink = this.mainButtonSetting.link;
  buttonIsDisplayed = this.mainButtonSetting.isDisplayed;
  buttonIsWebView = this.mainButtonSetting.isWebView;

  get validValue(): boolean {
    if (
      typeof this.buttonValue === "string" ||
      typeof this.buttonValue === "number"
    ) {
      return this.buttonValue.toString().length > 0;
    } else {
      return false;
    }
  }

  get validLink(): boolean {
    if (
      typeof this.buttonLink === "string" ||
      typeof this.buttonLink === "number"
    ) {
      return this.buttonLink.toString().length > 0;
    } else {
      return false;
    }
  }

  get validIsDisplayed(): boolean {
    return typeof this.buttonIsDisplayed === "boolean";
  }

  get validData() {
    return this.validValue && this.validLink && this.validIsDisplayed;
  }

  close() {
    this.$emit("close");
  }

  async updateSetting() {
    if (!this.validData) {
      return;
    }

    const editedMainButtonSetting = {
      value: this.buttonValue,
      link: this.buttonLink,
      isDisplayed: this.buttonIsDisplayed,
      isWebView: this.buttonIsWebView
    };

    const ref = this.ref;

    if (
      store.state.role?.data.type === "tutor" &&
      !(await this.mainButtonSettingExists())
    ) {
      await this.addMainButtonSetting(editedMainButtonSetting);
      return;
    }

    await this.updateMainButtonSetting(
      ref as firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >,
      editedMainButtonSetting
    );
  }

  async mainButtonSettingExists() {
    const schoolRef = store.state.school?.ref;
    const snapshot = await schoolRef!.collection("mainButtonSetting").get();
    return !snapshot.empty;
  }

  async addMainButtonSetting(setting: {
    [key: string]:
      | string
      | number
      | boolean
      | firebase.firestore.DocumentReference;
  }) {
    const schoolRef = store.state.school?.ref;
    try {
      store.commit("START_LOADING", "更新中...");
      await schoolRef!.collection("mainButtonSetting").add(setting);
    } catch (e) {
      alert("メインボタン情報の追加に失敗しました");
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async updateMainButtonSetting(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    setting: {
      [key: string]:
        | string
        | number
        | boolean
        | firebase.firestore.DocumentReference;
    }
  ) {
    try {
      store.commit("START_LOADING", "更新中...");
      await ref.update(setting);
    } catch (e) {
      alert("メインボタン情報の更新に失敗しました");
      console.log(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }
}
