
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MMiniAppEditModal from "@/components/mini_app/MMiniAppEditModal.vue";
import MMiniAppAddModal from "@/components/mini_app/MMiniAppAddModal.vue";
import MMainButtonSettingEditModal from "@/components/mini_app/MMainButtonSettingEditModal.vue";
import MLogoSettingEditModal from "@/components/mini_app/MLogoSettingEditModal.vue";
import store from "@/store";
import { Getter, State } from "@/store/helper";
import { miniAppsTableDefinition } from "@/utils/table";
import { User } from "@/entities/user";
import { MiniApp } from "../entities/mini_app";
import { saveErrorLog } from "@/api/error";
import firebase from "firebase";
import { MainButtonSettingType } from "../entities/main_button_setting";
import { Student } from "@/entities/student";
import { School } from "@/entities/school";
import { getStudentsBasedOnRole } from "@/api/student";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MMiniAppEditModal,
    MMiniAppAddModal,
    MMainButtonSettingEditModal,
    MLogoSettingEditModal
  }
})
export default class MiniAppsSettings extends Vue {
  isDisplayedAddSchoolModal = false;
  isDisplayedAddMiniAppModal = false;
  isDisplayedEditSchoolModal = false;
  isDisplayedMainButtonSettingEditModal = false;
  isDisplayedLogoSettingEditModal = false;
  searchKeyword = "";
  load = false;
  selectedId = "";
  selectedIds: string[] = [];
  appList: { [key: string]: string | number | boolean | string[] }[] = [];
  mainButtonSettingList: {
    [key: string]:
      | string
      | number
      | boolean
      | firebase.firestore.DocumentReference;
  } = {};

  definitions = miniAppsTableDefinition;
  tutors: User[] = [];

  @State("miniApps") miniApps!: MiniApp[];
  @State("mainButtonSetting") mainButtonSetting!: MainButtonSettingType[];
  @State("students") students!: Student[];
  @State("school") school?: School;
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isServiceProvider") isServicePrivider?: boolean;

  get selectedMiniApp(): MiniApp | null {
    const matchMiniApps = this.miniApps.filter(
      item => item.ref.id === this.selectedId
    );
    if (matchMiniApps.length === 0) {
      return null;
    }
    return matchMiniApps[0];
  }

  get filteredApps(): {
    [key: string]: string | number | boolean | string[];
  }[] {
    if (this.searchKeyword.length === 0) {
      return this.appList;
    }

    return this.appList.filter(
      data =>
        data?.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase()) ||
        data?.url
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
    );
  }

  get accessibleStudents(): Student[] {
    if (this.isAdmin) {
      return [];
    }
    const isServicePrivider = this.isServicePrivider
      ? this.isServicePrivider
      : false;
    const schoolDocId = this.school!.ref.id;
    const studetns = getStudentsBasedOnRole(
      isServicePrivider,
      this.students,
      schoolDocId
    );
    return studetns;
  }

  get accessibleStudentIds(): string[] {
    return this.accessibleStudents.map(student => student.ref.id);
  }

  showAddModal() {
    this.isDisplayedAddMiniAppModal = true;
  }

  showMainButtonSettingEditModal() {
    this.isDisplayedMainButtonSettingEditModal = true;
  }

  showLogoSettingEditModal() {
    this.isDisplayedLogoSettingEditModal = true;
  }

  showEditModal(id: string) {
    this.selectedId = id;
    this.isDisplayedEditSchoolModal = true;
  }

  changeSelectedId(id: string) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(item => item !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  async deleteApps() {
    if (this.selectedIds.length === 0) {
      return;
    }
    const confirmRes = confirm(
      `${this.selectedIds.length}個の独自アプリを本当に削除しますか？`
    );
    if (!confirmRes) {
      return;
    }
    store.commit(
      "START_LOADING",
      `${this.selectedIds.length}個の独自アプリを削除中...`
    );
    try {
      const schoolRef = store.state.school?.ref;
      const snapshot = await schoolRef?.collection("miniApps").get();
      const querySnapshot = await firebase
        .firestore()
        .collection("miniApps")
        .get();
      if (
        store.state.role?.data.type === "tutor" &&
        !!store.state.school &&
        snapshot?.empty
      ) {
        const notSelectedIds = this.miniApps
          .filter(miniApp => !this.selectedIds.includes(miniApp.ref.id))
          .map(miniApp => miniApp.ref.id);
        // 削除対象に選ばれなかったものだけを、そのスクールのminiAppに追加
        await this.addNotSelectedIdsToFirestore(
          schoolRef!,
          notSelectedIds,
          querySnapshot
        );
      } else {
        await Promise.all(
          this.miniApps.map(miniApp => {
            if (this.selectedIds.includes(miniApp.ref.id)) {
              return miniApp.ref.delete();
            }
            return Promise.resolve();
          })
        );
      }
      // 削除後の、連番（displayOrder）の歯抜け部分を埋める
      let ref:
        | firebase.firestore.DocumentReference
        | firebase.firestore.Firestore;
      if (store.state.role?.data?.type === "admin") {
        ref = firebase.firestore();
      } else if (
        store.state.role?.data?.type === "tutor" &&
        !!store.state.school
      ) {
        ref = store.state.school.ref;
      } else {
        return;
      }

      ref
        .collection("miniApps")
        .orderBy("displayOrder")
        .get()
        .then(querySnapshot => {
          let order = 1; // 初期のdisplayOrder

          querySnapshot.forEach(doc => {
            ref
              .collection("miniApps")
              .doc(doc.id)
              .update({
                displayOrder: order
              });
            order++;
          });
        })
        .catch(error => {
          console.error("Error updating documents: ", error);
        });
    } catch (e) {
      alert("削除に失敗しました。");
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete miniapps"
      );
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async addNotSelectedIdsToFirestore(
    schoolRef: firebase.firestore.DocumentReference,
    notSelectedIds: string[],
    querySnapshot: firebase.firestore.QuerySnapshot
  ) {
    try {
      const filteredDocs = querySnapshot.docs.filter(doc =>
        notSelectedIds.includes(doc.id)
      );

      const addPromises = filteredDocs.map(doc =>
        schoolRef.collection("miniApps").add(doc.data())
      );

      await Promise.all(addPromises);
      console.log("すべての ID が追加されました");
    } catch (error) {
      console.error("Firestore への追加中にエラーが発生しました:", error);
    }
  }

  async created(): Promise<void> {
    this.load = true;
    try {
      await Promise.all([
        store.dispatch("getStudents"),
        store.dispatch("getMiniApps"),
        store.dispatch("getMainButtonSetting")
      ]);
    } catch (error) {
      alert("情報の取得に失敗しました");
      console.error("情報の取得に失敗しました:", error);
    } finally {
      this.load = false;
    }

    this.appList = this.miniApps.map(miniApp => {
      const { data } = miniApp;
      let targetStudentIds = data!.targetStudentIds;
      if (targetStudentIds === undefined || targetStudentIds === null) {
        targetStudentIds = this.accessibleStudentIds;
      }
      return {
        id: miniApp.ref.id,
        name: data!.name,
        url: data!.url,
        displayOrder: data!.displayOrder,
        isWebView: data!.isWebView ? "有" : "無",
        serviceType: data!.serviceType ?? "",
        iconUrl: data!.iconUrl ?? "",
        isDisplayNone: data!.isDisplayNone ? "非表示" : "表示",
        targetStudentIds
      };
    });
    this.appList.sort((a, b) => {
      if (a.displayOrder < b.displayOrder) {
        return -1;
      }
      if (a.displayOrder > b.displayOrder) {
        return 1;
      }
      return 0;
    });

    if (!this.mainButtonSetting || this.mainButtonSetting.length === 0) {
      this.mainButtonSettingList = {};
    } else {
      const firstSetting = this.mainButtonSetting[0];
      const { ref, data } = firstSetting;
      this.mainButtonSettingList = {
        ref: ref!,
        value: data!.value,
        link: data!.link,
        isDisplayed: data!.isDisplayed,
        isWebView: data!.isWebView
      };
    }
  }
}
