
import MBaseModal from "@/components/MBaseModal.vue";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MIconFileInput from "../form/MIconFileInput.vue";
import { Options, Vue } from "vue-class-component";
import firebase from "firebase/app";
import "firebase/storage";
import store from "@/store";
import { Getter } from "@/store/helper";

@Options({
  components: {
    MBaseModal,
    MIcon,
    MButton,
    MTextField,
    MIconFileInput,
    MSelectBox
  },
  emits: ["close"]
})
export default class MMiniAppEditModal extends Vue {
  url = "";
  logoUrl = "";
  convertedLogoUrl = "";
  isFirstTime = false;
  isIconSelectionActive = false;
  file?: Blob;

  @Getter("isAdmin") isAdmin!: boolean;

  get logoPath(): string {
    const schoolRefId = store.state.school?.ref.id;
    return `appIcons/schools/${schoolRefId}/appLogo/logo`;
  }

  get defaultLogoPath(): string {
    return "appIcons/admin/appLogo/mingaku_icon.png";
  }

  close() {
    this.$emit("close");
  }

  addIcon(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file: Blob = fileInput.files[0];
      this.file = file;
      const reader = new FileReader();

      reader.onload = e => {
        this.convertedLogoUrl = e.target?.result as string;
      };

      reader.readAsDataURL(fileInput.files[0]);
    }
    this.isIconSelectionActive = false;
  }

  async deleteIcon() {
    this.file = undefined;
    this.convertedLogoUrl = await this.getImageUrl(this.defaultLogoPath);
    this.isIconSelectionActive = false;
  }

  async deleteFromFirebaseStorage(
    storageRef: firebase.storage.Reference
  ): Promise<void> {
    try {
      await storageRef.delete().then(() => {
        console.log("File deleted successfully");
      });
    } catch (e) {
      alert("ロゴ画像の削除に失敗しました" + e);
    }
  }

  async uploadToFirebaseStorage(
    storagePath: string,
    file: Blob | undefined
  ): Promise<void> {
    const storageRef = firebase.storage().ref(storagePath);
    if (file) {
      await storageRef.put(file).then(snapshot => {
        console.log("Logo successfully added", snapshot);
      });
    } else {
      await this.deleteFromFirebaseStorage(storageRef);
    }
  }

  async update() {
    try {
      store.commit("START_LOADING", "更新中...");
      await this.uploadToFirebaseStorage(this.logoPath, this.file);
    } catch (e) {
      alert("ログ情報のアップデートに失敗しました" + e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async getImageUrl(storagePath: string | undefined): Promise<string> {
    if (!storagePath) {
      return "";
    }
    const storageRef = firebase.storage().ref(storagePath);
    try {
      return await storageRef.getDownloadURL();
    } catch (e) {
      if (e.code === "storage/object-not-found") {
        return "";
      }
      alert("画像のURL取得に失敗しました:" + e);
      return "";
    }
  }

  async created(): Promise<void> {
    let path = await this.getImageUrl(this.logoPath);
    if (!path) {
      path = await this.getImageUrl(this.defaultLogoPath);
    }

    this.convertedLogoUrl = path;
  }
}
