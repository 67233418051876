
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MIconFileInput from "@/components/form/MIconFileInput.vue";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { saveErrorLog } from "@/api/error";
import { MiniApp } from "@/entities/mini_app";
import firebase from "firebase/app";
import { Student } from "@/entities/student";
import { Getter } from "@/store/helper";

@Options({
  components: {
    MBaseModal,
    MButton,
    MSelectBox,
    MIconFileInput,
    MTextField,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    miniApps: Array,
    students: Array
  }
})
export default class MMiniAppAddModal extends Vue {
  miniApps!: MiniApp[];
  students: Student[] = [];
  isWebView = false;
  displayOrder = this.miniApps.length + 1;
  isDisplayNone = false;
  targetStudentIds: string[] | null = [];
  appName = "";
  serviceType = "";
  url = "";
  iconUrl = "";
  isFirstMiniApp = false;
  isIconSelectionActive = false;
  src = "";
  file?: Blob;
  toAll = true;
  distributionType = "all";

  @Getter("isAdmin") isAdmin!: boolean;

  get studentIds(): string[] {
    if (!this.students) {
      return [];
    }
    return this.students.map(student => student.ref.id);
  }

  get validAppName(): boolean {
    return this.appName.length > 0;
  }

  get validUrl(): boolean {
    return this.url.length > 0;
  }

  get validDisplayOrder(): boolean {
    return String(this.displayOrder).length > 0;
  }

  get validData() {
    return this.validAppName && this.validUrl && this.validDisplayOrder;
  }

  handleToAllFlagChange(): void {
    this.distributionType = this.toAll ? "all" : "custom";
  }

  onStudentsUpdated(students: Student[]) {
    const studentIds = students.map(student => student.ref.id);
    this.targetStudentIds = studentIds;
  }

  addIcon(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file: Blob = fileInput.files[0];
      this.file = file;
      const reader = new FileReader();

      reader.onload = e => {
        this.src = e.target?.result as string;
      };

      reader.readAsDataURL(fileInput.files[0]);
    }
    this.isIconSelectionActive = false;
  }

  deleteIcon() {
    this.src = "";
    this.iconUrl = "";
    this.isIconSelectionActive = false;
  }

  async saveUrltoStorage(
    iconUrl: string,
    file: Blob | undefined,
    isAdmin: boolean,
    refId: string,
    addedRef: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    >,
    hasSrc: boolean
  ) {
    if (!hasSrc) {
      return;
    }

    let storagePath: string;
    if (isAdmin) {
      storagePath = `appIcons/admin/miniApps/${addedRef.id}`;
    } else {
      storagePath = `appIcons/schools/${refId}/miniApps/${addedRef.id}`;
    }

    await Promise.all([
      this.uploadToFirebaseStorage(file, storagePath),
      addedRef.update({
        iconUrl
      })
    ]);
  }

  async uploadToFirebaseStorage(file: Blob | undefined, storagePath: string) {
    try {
      const storageRef = firebase.storage().ref(storagePath);
      if (file) {
        await storageRef.put(file).then(snapshot => {
          console.log("Uploaded a blob or file!", snapshot);
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }

  async copyAdminMiniAppsToSchoolCollection(
    schoolRef: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    >
  ) {
    const querySnapshot = await firebase
      .firestore()
      .collection("miniApps")
      .get();

    const addDocDataPromises = querySnapshot.docs
      .map(doc => {
        const docData = doc.data();
        if (doc.id !== "miniApps") {
          schoolRef.collection("miniApps").add(docData);
        }
      })
      .filter(promise => promise !== undefined && promise !== null);

    await Promise.all(addDocDataPromises);
  }

  async registerMiniApp() {
    if (!this.validData) {
      return;
    }
    if (!store.state.role) {
      return;
    }

    let ref:
      | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      | firebase.firestore.Firestore;
    if (store.state.role?.data?.type === "admin") {
      ref = firebase.firestore();
    } else if (store.state.role?.data?.type === "tutor" && store.state.school) {
      ref = store.state.school.ref;
    } else {
      return;
    }
    const miniAppData = {
      name: this.appName,
      isWebView: this.isWebView,
      displayOrder: Number(this.displayOrder),
      url: this.url,
      serviceType: this.serviceType,
      iconUrl: this.iconUrl,
      isDisplayNone: this.isDisplayNone,
      distributionType: this.distributionType,
      targetStudentIds: this.isAdmin ? null : this.targetStudentIds
    };

    try {
      store.commit("START_LOADING", "新規独自アプリ登録中...");
      const snapshot = await ref.collection("miniApps").get();

      if (
        store.state.role?.data.type === "tutor" &&
        !!store.state.school &&
        snapshot?.empty
      ) {
        this.isFirstMiniApp = true;
        await this.copyAdminMiniAppsToSchoolCollection(
          ref as firebase.firestore.DocumentReference<
            firebase.firestore.DocumentData
          >
        );
        await store.dispatch("getMiniApps");
      }

      // まずは、iconUrl抜きでFirebaseに保存し、レフを格納
      const newMiniAppRef = await ref
        .collection("miniApps")
        .add({ ...miniAppData });

      // アイコンがあれば画像を保存し、パスをFirebaseで更新
      await this.saveUrltoStorage(
        this.iconUrl,
        this.file,
        store.state.role?.data?.type === "admin",
        (ref as firebase.firestore.DocumentReference).id,
        newMiniAppRef,
        this.src !== ""
      );

      const addedMiniApp = {
        ref: newMiniAppRef,
        data: miniAppData
      };
      const miniApps = this.isFirstMiniApp
        ? [...store.state.miniApps, { ...addedMiniApp }]
        : [...this.miniApps, { ...addedMiniApp }];
      const sortedMiniApps = miniApps.sort((x, y) => {
        if (x.data!.displayOrder === y.data!.displayOrder) {
          return x.ref.id === addedMiniApp.ref.id ? -1 : 1; // 並び順が同じ場合は、更新した独自アプリを優先する
        }
        return x.data!.displayOrder - y.data!.displayOrder;
      });
      await Promise.all(
        sortedMiniApps.map(async (miniApp, index) => {
          // 既存のdisplayOrderが使用されると、そのdisplayOrder以降の独自アプリの並び順が全て変わるため、displayOrderの更新を行う
          await miniApp.ref.update({
            displayOrder: index + 1
          });
        })
      );
    } catch (e) {
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register miniapp"
      );
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  close() {
    this.$emit("close");
  }

  created(): void {
    this.targetStudentIds = this.studentIds;
  }
}
