
import MBaseModal from "@/components/MBaseModal.vue";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";
import MIconFileInput from "@/components/form/MIconFileInput.vue";
import { Options, Vue } from "vue-class-component";
import { MiniApp } from "@/entities/mini_app";
import store from "@/store";
import { saveErrorLog } from "@/api/error";
import firebase from "firebase/app";
import "firebase/storage";
import { Student } from "@/entities/student";
import { Getter } from "@/store/helper";

@Options({
  components: {
    MBaseModal,
    MIcon,
    MButton,
    MTextField,
    MIconFileInput,
    MSelectBox,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    appId: String,
    miniApps: Array,
    miniApp: Object,
    students: Array
  }
})
export default class MMiniAppEditModal extends Vue {
  appId!: string;
  miniApps!: MiniApp[];
  miniApp!: MiniApp;
  students!: Student[];
  isWebView!: boolean;
  displayOrder!: number;
  appName = "";
  serviceType = "";
  isDisplayNone = false;
  targetStudentIds: string[] | null = [];
  url = "";
  iconUrl = "";
  convertedIconUrl = "";
  file?: Blob;
  initialDisplayOrder!: number;
  isFirstTimeMiniApp = false;
  isIconSelectionActive = false;
  toAll = true;
  distributionType = "all";

  @Getter("isAdmin") isAdmin!: boolean;

  get studentIds(): string[] {
    if (!this.students) {
      return [];
    }
    return this.students.map(student => student.ref.id);
  }

  get validAppName(): boolean {
    return this.appName.length > 0;
  }

  get validUrl(): boolean {
    return this.url.length > 0;
  }

  get validDisplayOrder(): boolean {
    return String(this.displayOrder).length > 0;
  }

  get validData() {
    return this.validAppName && this.validUrl && this.validDisplayOrder;
  }

  close() {
    this.$emit("close");
  }

  handleToAllFlagChange(): void {
    this.distributionType = this.toAll ? "all" : "custom";
  }

  onStudentsUpdated(students: Student[]) {
    const studentIds = students.map(student => student.ref.id);
    this.targetStudentIds = studentIds;
  }

  addIcon(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file: Blob = fileInput.files[0];
      this.file = file;
      const reader = new FileReader();

      reader.onload = e => {
        this.convertedIconUrl = e.target?.result as string;
      };

      reader.readAsDataURL(fileInput.files[0]);
    }
    this.isIconSelectionActive = false;
  }

  deleteIcon() {
    this.convertedIconUrl = "";
    this.iconUrl = "";
    this.isIconSelectionActive = false;
  }

  async saveUrltoStorage(
    currentPass: string,
    hasUrl: boolean,
    file: Blob | undefined,
    ref: firebase.firestore.DocumentReference | firebase.firestore.Firestore,
    isAdmin: boolean,
    appId: string
  ): Promise<string> {
    let storagePath;
    if (isAdmin) {
      storagePath = `appIcons/admin/miniApps/${appId}`;
    } else {
      storagePath = `appIcons/schools/${
        (ref as firebase.firestore.DocumentReference).id
      }/miniApps/${appId}`;
    }

    if (hasUrl && file === undefined) {
      return currentPass;
    } else if (hasUrl && file) {
      await Promise.all([
        this.uploadToFirebaseStorage(file, storagePath, hasUrl),
        ref
          .collection("miniApps")
          .doc(appId)
          .update({ iconUrl: storagePath })
      ]);
      return storagePath;
    } else {
      await Promise.all([
        this.uploadToFirebaseStorage(file, storagePath, hasUrl),
        ref
          .collection("miniApps")
          .doc(appId)
          .update({ iconUrl: "" })
      ]);
      return "";
    }
  }

  async uploadToFirebaseStorage(
    file: Blob | undefined,
    storagePath: string,
    hasUrl: boolean
  ) {
    if (!hasUrl) {
      firebase
        .storage()
        .ref(storagePath)
        .delete();
      return;
    }

    try {
      const storageRef = firebase.storage().ref(storagePath);
      if (file) {
        await storageRef.put(file).then(snapshot => {
          console.log("Uploaded a blob or file!", snapshot);
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }

  async copyAdminMiniAppsToSchoolCollection(
    schoolRef: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    >,
    miniAppData: { [key: string]: string | number | boolean | string[] | null }
  ): Promise<firebase.firestore.DocumentReference> {
    const querySnapshot = await firebase
      .firestore()
      .collection("miniApps")
      .get();

    const addDocDataPromises = querySnapshot.docs
      .map(doc => {
        if (doc.id !== this.appId && doc.id !== "miniApps") {
          const docData = doc.data();
          return schoolRef.collection("miniApps").add(docData);
        }
      })
      .filter(promise => promise !== undefined && promise !== null);

    const [, newRef] = await Promise.all([
      addDocDataPromises,
      schoolRef.collection("miniApps").add(miniAppData)
    ]);

    return newRef;
  }

  async update() {
    if (!this.validData) {
      return;
    }

    // 新いデータをここで作るが、iconUrlだけは未整備状態
    // （塾独自のminiAppコレクションがないユーザーのために、iconUrlは無視して先に新しい情報を）
    const miniAppData = {
      name: this.appName,
      isWebView: this.isWebView,
      displayOrder: Number(this.displayOrder),
      url: this.url,
      serviceType: this.serviceType,
      iconUrl: this.iconUrl,
      isDisplayNone: this.isDisplayNone,
      distributionType: this.distributionType,
      targetStudentIds: this.isAdmin ? null : this.targetStudentIds
    };

    const schoolRef = store.state.school?.ref;
    const snapshot = await schoolRef?.collection("miniApps").get();
    let newRefForFirstTimeMiniApp: firebase.firestore.DocumentReference;

    if (
      store.state.role?.data.type === "tutor" &&
      !!store.state.school &&
      !!schoolRef &&
      snapshot?.empty
    ) {
      this.isFirstTimeMiniApp = true;
      newRefForFirstTimeMiniApp = await this.copyAdminMiniAppsToSchoolCollection(
        schoolRef,
        miniAppData
      );
      await store.dispatch("getMiniApps");
    }

    try {
      store.commit("START_LOADING", "更新中...");

      let ref:
        | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
        | firebase.firestore.Firestore;
      if (store.state.role?.data.type === "admin") {
        ref = firebase.firestore();
      } else if (store.state.role?.data.type === "tutor") {
        ref = schoolRef!;
      } else {
        return;
      }

      // ここで未整備だったiconUrlを調整しなおす
      miniAppData.iconUrl = await this.saveUrltoStorage(
        this.iconUrl,
        this.convertedIconUrl ? true : false,
        this.file,
        ref,
        store.state.role?.data.type === "admin",
        this.isFirstTimeMiniApp ? newRefForFirstTimeMiniApp!.id : this.appId
      );

      if (!this.isFirstTimeMiniApp) {
        await this.miniApp.ref.update({ ...miniAppData });
      }

      const updatedMiniApp = {
        ref: this.isFirstTimeMiniApp
          ? newRefForFirstTimeMiniApp!
          : this.miniApp.ref,
        data: miniAppData
      };
      const miniApps: MiniApp[] = this.isFirstTimeMiniApp
        ? [...store.state.miniApps]
        : [
            ...this.miniApps.filter(
              miniApp => miniApp.ref.id !== updatedMiniApp.ref!.id
            ),
            { ...(updatedMiniApp as MiniApp) }
          ];
      const sortedMiniApps = miniApps.sort((x, y) => {
        if (x.data!.displayOrder === y.data!.displayOrder) {
          // displayOrderを上げるか下げるかで挙動が逆転するため、ここで条件分岐（順位変動しない時はどっちでも良い）
          if (this.initialDisplayOrder >= this.displayOrder) {
            return x.ref!.id === updatedMiniApp.ref!.id ? -1 : 1; // 並び順が同じ場合は、更新した独自アプリを優先する
          } else {
            return x.ref!.id === updatedMiniApp.ref!.id ? 1 : -1;
          }
        }
        return x.data!.displayOrder - y.data!.displayOrder;
      });

      await Promise.all(
        sortedMiniApps.map(async (miniApp, index) => {
          // 既存のdisplayOrderが使用されると、そのdisplayOrder以降の独自アプリの並び順が全て変わるため、displayOrderの更新を行う
          await miniApp.ref!.update({
            displayOrder: index + 1
          });
        })
      );
    } catch (e) {
      alert("データの編集に失敗しました");
      console.log(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update school"
      );
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  async getImageUrl(storagePath: string | undefined) {
    if (!storagePath) {
      return "";
    }
    const storageRef = firebase.storage().ref(storagePath);
    try {
      return await storageRef.getDownloadURL();
    } catch (error) {
      console.error("画像のURL取得に失敗しました:", error);
      return "";
    }
  }

  async created() {
    this.appName = this.miniApp.data!.name;
    this.isWebView = this.miniApp.data!.isWebView;
    this.displayOrder = this.miniApp.data!.displayOrder;
    this.url = this.miniApp.data!.url;
    this.serviceType = this.miniApp.data!.serviceType ?? "";
    this.iconUrl = this.miniApp.data!.iconUrl ?? "";
    this.initialDisplayOrder = this.displayOrder;
    this.isDisplayNone = this.miniApp.data!.isDisplayNone ?? false;
    this.distributionType = this.miniApp.data!.distributionType ?? "all";
    this.toAll = this.distributionType === "all" ? true : false;

    const targetStudentIdsInData = this.miniApp.data!.targetStudentIds;
    if (
      targetStudentIdsInData === undefined ||
      targetStudentIdsInData === null
    ) {
      this.targetStudentIds = this.studentIds;
    } else {
      this.targetStudentIds = targetStudentIdsInData;
    }

    this.convertedIconUrl = this.iconUrl
      ? await this.getImageUrl(this.iconUrl)
      : "";
  }
}
